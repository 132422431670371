<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select v-model="query.buyerId" url="api/distributor" placeholder="经销商" clearable filterable @change="toQuery" value-field="enterpriseId" class="filter-item" style="width: 180px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="创建开始日期" end-placeholder="创建结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-input v-model="query.formCode" class="filter-item" :maxlength="20" placeholder="输入单号搜索" @keypress.enter.native="toQuery" style="width: 160px;" />
      <el-button class="filter-item" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" style="width: 100%;">
      <el-table-column prop="formCode" label="退货单号" width="125" />
      <el-table-column prop="orderFormCode" label="关联订单号" width="150" />
      <el-table-column prop="buyerName" label="经销商" min-width="190" show-overflow-tooltip />
      <el-table-column prop="totalMoney" label="应退现金" width="120" align="right" :formatter="$price" />
      <el-table-column prop="realBackMoney" label="实退现金" width="120" align="right" :formatter="$price" />
      <el-table-column prop="rebateAmount" label="应退返利金" width="120" align="right" :formatter="$price" />
      <!-- <el-table-column label="发货地址" min-width="220" show-overflow-tooltip>
        <template slot-scope="scope">{{scope.row.sendProvince}}{{scope.row.sendCity}}{{scope.row.sendDistrict}}{{scope.row.sendAddress}}</template>
      </el-table-column>-->
      <el-table-column prop="createAt" label="创建日期" width="120" :formatter="r => { return new Date(r.createAt).format('yyyy/MM/dd'); }" />
      <el-table-column prop="info" label="备注" min-width="240" />
      <el-table-column label="状态" width="90" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="50" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row, 'handle')" v-if="scope.row.status === 1">受理</el-button>
          <el-button size="mini" type="text" @click="edit(scope.row)" v-else>查看</el-button>
        </div>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    <e-form ref="form" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "../form";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 状态与操作  编辑中 审批未通过 、待审批 审批通过 已关闭
      status: [
        {
          type: "info",
          label: "编辑中",
        },
        {
          type: "warning",
          label: "待审批",
        },
        {
          type: "success",
          label: "已同意",
        },
        {
          type: "danger",
          label: "已拒绝",
        },
        {
          type: "success",
          label: "处理中",
        },
        {
          type: "danger",
          label: "已完成",
        },
      ],
      ssquery: {
        buyerId: null,
        dateRange: null,
        formCode: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/orderBackForm";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.beginTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      delete query.dateRange;
      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
          sort: "createAt,desc",
          notStatus: 0,
        }, //notStauts:0不显示 编辑中的数据
        query
      );
      return true;
    },
    edit(data, action = null) {
      this.$refs.form && this.$refs.form.resetForm(data, null, action);
    },
  },
};
</script>